<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('withdraw_requests')" @filter-div-status="datatable.filterStatus=$event" :isFilter="true"/>
            </template>
            <template v-slot:header-mobile>
                <Header-mobile @filter-div-status="datatable.filterStatus=$event" :title="$t('withdraw_requests')" :isFilter="true"/>
            </template>
            <div>
                <datatable-filter v-show="datatable.filterStatus" @filter="$refs.datatable.getRows()"
                                  @filterClear="$refs.datatable.filterClear()" :export-excel="true"
                                  @exportExcel="$refs.datatable.excel()">
                    <b-row>
                        <b-col md="6">
                            <b-form-group :label="$t('semester')">
                                <SemestersSelectbox v-model="datatable.queryParams.filter.semester_id"></SemestersSelectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('student_number')">
                                <b-form-input v-model="datatable.queryParams.filter.student_number"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('name')">
                                <b-form-input v-model="datatable.queryParams.filter.name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('program_name')">
                                <program-selectbox v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('surname')">
                                <b-form-input v-model="datatable.queryParams.filter.surname"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </datatable-filter>

                <datatable ref="datatable"
                           :isLoading.sync="datatable.isLoading"
                           :columns="datatable.columns"
                           :rows="datatable.rows"
                           :total="datatable.total"
                           :queryParams="datatable.queryParams"
                           :lineNumbers="false"
                           v-show="datatable.showTable"
                           :data-function="callService">
                </datatable>

            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import Datatable from "@/components/datatable/Datatable";

import WithDrawService from "@/services/WithDrawService";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

export default {
    components: {
        SemestersSelectbox,
        ProgramSelectbox,
        FacultySelectbox,
        DatatableFilter,
        Datatable,
        HeaderMobile,
        Header,
        AppLayout},
    metaInfo() {
        return {
            title: this.$t('withdraw_requests')
        }
    },
    data() {
        return {
            trainee: {},
            student: {},
            datatable: {
                columns: [
                    {
                        label: "",
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.detail(row)
                                }
                            }
                        ]
                    },
                    {field: "student_number", label: this.toUpperCase("student_number"), hidden: false, sortable: false},
                    {field: "name", label: this.toUpperCase("name"), hidden: false, sortable: false},
                    {field: "surname", label: this.toUpperCase("surname"), hidden: false, sortable: false},
                    {field: "faculty_name", label: this.toUpperCase("faculty_name"), hidden: false, sortable: false},
                    {field: "program_name", label: this.toUpperCase("program_name"), hidden: false, sortable: false},
                    {field: "academic_year", label: this.toUpperCase("academic_year"), hidden: false, sortable: false},
                    {field: "semester", label: this.toUpperCase("semester"), hidden: false, sortable: false},
                    {field: "slot_type", label: this.toUpperCase("slot_type"), hidden: false, sortable: false},
                    {field: "slot_semester", label: this.toUpperCase("slot_semester"), hidden: false, sortable: false},
                    {field: "course_code", label: this.toUpperCase("course_code"), hidden: false, sortable: false},
                    {field: "course_name", label: this.toUpperCase("course_name"), hidden: false, sortable: false},
                    {field: "credit", label: this.toUpperCase("credit"), hidden: false, sortable: false},
                    {field: "created_at", label: this.toUpperCase("created_at"), hidden: false, sortable: false},
                ],
                showTable: false,
                isLoading: false,
                filterStatus:true,
                rows: [],
                queryParams: {
                    filter: {
                        student_number: null,
                        name:null,
                        surname:null,
                        semester_id:null,
                        faculty_code:null,
                        program_code:null
                    },
                    page: 1,
                    limit: 20
                }
            },
        }
    },
    methods: {
        callService(config) {
            return  WithDrawService.getAllWithDraw(config)
        },

        detail(row){
            this.$router.push({path: "/request/withDraw/detail/"+row.id})
        }
    }
}
</script>
